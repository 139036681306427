<template>
  <div class="page-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.name") }}</label>
        <vs-input
          v-model="value.name"
          v-validate="'required'"
          class="w-full"
          :class="{
            'danger-border-select': errorName && errors.has('hookName')
            }"
          name="hookName"
          :danger="errorName && errors.has('hookName')"
          :danger-text="$t('shops.errors.name_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.localization") }}</label>
        <v-select
            name="hookType"
            v-model="value.type"
            :placeholder="$t('shops.select_localization')"
            class="w-full"
            :value="hookSelected"
            :options="
            hookTypeOptions.map(option => ({
                label: option.label,
                value: option.value
            }))
            "
            @input="option => selectHook(option)"
            v-validate="'required'"
            :class="{
            'danger-border-select': errorType && errors.has('hookType')
            }"
            :danger="errorType && errors.has('hookType')"
            :danger-text="$t('shops.errors.type_is_required')"
        />
      </div>
    </div>

    <div class="vx-col 1/3">
      <vs-button
        @click="validateAndSubmit(value)"
        class="mt-base"
        type="filled"
        >{{ $t("shops.save") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import notifications from "@/modules/Shared/Mixins/notifications.js";
import i18n from "@/i18n";

const defaultPage = () => ({
    id: 0,
    name: "",
    type: "",
    resort_id: 0
});

export default {
  name: "PageForm",

  props: {
    value: { type: Object, default: () => defaultPage }
  },

  mixins: [notifications],

  data() {
    return {
      hookTypeOptions: [
        { label: "Email", value: "email" }
      ],
      hookSelected: "",
      errorName: false,
      errorType: false
    };
  },

  methods: {
    selectHook(option) {
        this.hookSelected = option === null ? option : option.value;
    },
    validateAndSubmit(value) {
      this.$validator.validateAll().then(result => {
        this.value.type = this.hookSelected.toLowerCase()
        if (result) {
          this.$emit("submit", value);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.page_validation_failed"));
          if (!this.value.name) this.errorName = true;
          if (!this.value.type) this.errorType = true;
        }
      });
    }
  },
};
</script>

<style></style>
