import i18n from "@/i18n";

const table_functions = {
  data() {
    return {
    }
  },
  methods: {
    async load(params) {
        this.$vs.loading();
        try {
            await this.loadData(params);
        } catch (e) {
            console.warn(e);
            this.$vs.loading.close();
        } finally {
            this.$vs.loading.close();
        }
    },

    loadDataWithFilters() {
        if (this.exportRequest) {
            this.exportRequest.payload = { ...this.exportPayload };
            delete this.exportRequest.payload.page;
        }
        this.load({
            ...this.exportPayload
        });
    },

    onSort(payload) {
        let sortOrder = payload.order === "asc" ? "+" : "-";
        if (payload.order) this.exportPayload.sort = sortOrder + payload.key;
        else this.exportPayload.sort = this.defaultSort;
        this.loadDataWithFilters();
    },
  
    onSearch(payload) {
        this.exportPayload.q = payload;
        this.exportPayload.page = 1;
        this.loadDataWithFilters();
    },

    onTablePageChange(page) {
        this.exportPayload.page = page;
        this.loadDataWithFilters();
    },

    onPerPageChange(perPage) {
        this.exportPayload.page_size = perPage;
        this.loadDataWithFilters();
    },

    onDateRangeSearch(range) {
        this.exportPayload.created_at_from = range.from;
        this.exportPayload.created_at_to = range.to;
        this.loadDataWithFilters();
    },

    onDateRangeClear() {
        delete this.exportPayload.created_at_from;
        delete this.exportPayload.created_at_to;
        this.exportPayload.sort = this.defaultSort;
        this.loadDataWithFilters();
    },

    onSelected(selected, name, property) {
        this.$router.push({ name: name, params: {[property]: selected.id}});
    },

    onCreate() {
        this.formData = this.default();
        this.showForm = !this.showForm;
    },

    async onRemove(data, body) {
        this.$vs.loading();
        try {
            await this.removeData(data);
        } catch(e){
            this.$vs.loading.close();
        } finally {
            this.notifySuccess(
                i18n.t('shops.removed'),
                i18n.t(body)+" "+i18n.t('shops.correctly_removed')
            );
            this.$vs.loading.close();
        }
    },
  }
}

export default table_functions