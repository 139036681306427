<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate(); removeFormData();">{{
        $t("shops.back")
      }}</vs-button>
      <transition name="fade" moded="out-in">
        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.hook')"
            @update="loadUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <hook-form v-model="formData" @submit="submitHookForm" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import HookForm from "@/modules/Shops/Pages/forms/HookForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "Hooks",

  mixins: [notifications, table_functions],

  components: {
    DataTable,
    HookForm
  },

  props: {
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/hooks", {
      hooksList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      pageById: "getById"
    }),

    tableData() {
      return this.hooksList.map(this.hooksToTableData);
    },
    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      }
    }
  },

  methods: {
    ...mapActions("shops/hooks", {
      loadData: "load",
      removeData: "remove",
      updateHook: "update",
      createHook: "create"
    }),

    hooksToTableData(hook) {
      return {
        id: hook.id,
        name: hook.name,
        type: hook.type,
        created_at: hook.created_at,
        updated_at: hook.updated_at
      };
    },

    removeFormData() {
      this.formData = this.default();
    },

    async submitHookForm(hook) {
      this.$vs.loading();
      try {
        if (hook.id === 0) {
          delete hook.id;
          await this.createHook(hook);
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.hook')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updateHook(hook);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.hook')} ${hook.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.error.failed_to_save_hook"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async loadUpdate(page) {
      this.showForm = true;
      this.formData = Object.assign({},this.formData,this.pageById(page.id));
    },

    default() {
      return {
        id: 0,
        name: "",
        type: "",
        resort_id: 0,
      }
    }
  },

  created() {
    this.$store.commit("shops/hooks/RESET_RESOURCE");
    this.load();
  }
};
</script>

<style></style>
